import React from "react";
import { darken } from "polished";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Typography, useTheme, Theme } from "@material-ui/core";
import MailchimpSubscribe from "../components/mailchimp-subscribe";
import SectionWithBackground from "../components/section-with-background";
import { ThemeWithAccent } from "../layouts/theme";


const SubscribeBlock: React.FC = () => {
    const theme = useTheme<ThemeWithAccent>();
    
    const data = useStaticQuery(graphql`query {
        subscribe: generalJson(block: {eq: "subscribe"}) {
            tagline
            cta
            backgroundImage {
                childImageSharp {
                    fluid(maxWidth: 4160, cropFocus: ATTENTION) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    }`);

    const background = theme.accent.main;

    return <SectionWithBackground image={data.subscribe.backgroundImage} overlay={darken(0.085,background)} gradientTop={0.55} gradientBottom={0.75}>
        <Container id="subscribe" maxWidth="sm" style={{color:theme.accent.contrastText}}>
            <Typography variant="h4" align="center" gutterBottom>{data.subscribe.tagline}</Typography>
            <MailchimpSubscribe cta={data.subscribe.cta} textColor={theme.palette.primary.light} />
        </Container>
    </SectionWithBackground>
}

export default SubscribeBlock;