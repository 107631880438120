import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image";
import Section from "../components/section";
import SEO from "../components/seo"
import { Container, Box, useTheme, Theme, Typography, Grid } from "@material-ui/core";
import MainLayout from "../layouts/mainLayout";
import HeroContainer from "../components/hero-container";
import IconTextBlock from "../components/icon-text-block";
import SubscribeBlock from "../composed/subscribe-block";
import { TextOverlayType } from "../components/styledComponents";
import LinkButton from "../components/link-button";
import { darken } from "polished";
import BlogTeaser from "../components/blog-teaser";

interface LocationProps {
  location: Location;
}

const IndexPage: React.FC<LocationProps> = (props) => {
  const theme = useTheme<Theme>();

  const data = useStaticQuery(graphql`
    query {
      seo: homeJson(block: {eq: "seo" }) {
        title
        description
        image {
          childImageSharp {
              resize(width: 750, height: 300, cropFocus: ENTROPY) {
                  src
                  width
                  height
              }
          }
        }
      }
      header: homeJson(block: {eq: "header"}) {
        text
        ctaText
        ctaUrl
        image {
          childImageSharp {
            fluid(maxWidth: 4160, maxHeight: 3120, cropFocus: ATTENTION) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        product {
          childImageSharp {
            fluid(maxWidth:650, cropFocus: ENTROPY) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      features: homeJson(block: {eq: "features"}) {
        sectionTitle
        product {
            childImageSharp {
              fluid(maxWidth:415, cropFocus: ATTENTION) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          cta
          ctaUrl
          list {
             icon
             title
             content
          }
      }
    }
  `)

  return (
    <MainLayout transparentNav>
      <SEO {...data.seo} location={props.location} />

      <HeroContainer {...data.header} heroHeight={100} overlayColor={theme.palette.secondary.main}>
        <Container maxWidth="md">
          <Grid container alignItems="center">
            <Grid item sm={5}>
              <Img fluid={data.header.product.childImageSharp.fluid} />
            </Grid>
            <Grid item xs={12} sm={7} alignContent="center">
              <TextOverlayType shadowColor={darken(0.65, theme.palette.secondary.main)} variant="h2" gutterBottom align="center">{data.header.text}</TextOverlayType>
              <Box display="flex" justifyContent="center">
                <LinkButton url={data.header.ctaUrl} variant="contained" color="primary" size="large">
                  <Typography variant="h5">{data.header.ctaText}</Typography>
                </LinkButton>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </HeroContainer>

      <FeatureBlock {...data.features} />
      <BlogTeaser tightTop />
      <SubscribeBlock />
    </MainLayout>
  )
}

interface FeatureBlockProps {
  sectionTitle: string,
  product: import("gatsby-source-filesystem").FileSystemNode;
  cta: string,
  ctaUrl: string,
  list: [{
    icon: string,
    title: string,
    content: string
  }]
}

const FeatureBlock: React.FC<FeatureBlockProps> = (props) => {
  let left = [];
  let right = [];

  const half = Math.ceil(props.list.length / 2);
  let i = 0;
  for (const f of props.list) {
    if (i++ >= half) {
      right.push(f);
    }
    else {
      left.push(f);
    }
  }

  return <Section tightBottom>
    <Container maxWidth="lg">
      <Typography variant="h2" align="center">{props.sectionTitle}</Typography>
      <Grid container>
        <Grid item container xs={12} sm={4} justify="space-evenly" direction="column">
          {left.map((f, idx) => <Box py={2} px={3}>
            <IconTextBlock key={idx} {...f} />
          </Box>)}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Link to={props.ctaUrl}>
            <Img fluid={(props.product.childImageSharp as any).fluid} />
          </Link>
          <Box textAlign="center" py={2} pr={2}>
            <LinkButton url={props.ctaUrl} variant="contained" color="primary" size="large">
              {props.cta}
            </LinkButton>
          </Box>
        </Grid>
        <Grid item container xs={12} sm={4} justify="space-evenly" direction="column">
          {right.map((f, idx) => <Box py={2} px={3}>
            <IconTextBlock key={idx} {...f} />
          </Box>)}
        </Grid>
      </Grid>
    </Container>
  </Section>

}


export default IndexPage
