import React from "react";
import BackgroundImage from "gatsby-background-image";
import styled from "@emotion/styled";
import { Box, Container} from "@material-ui/core";
import { TextOverlayType } from "./styledComponents";

interface HeroContainerProps {
    image: import("gatsby-source-filesystem").FileSystemNode;
    heroHeight: number;
    overlayColor: string;
}

export const hexToRgb = (hex: string) => {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
};

export const getBackgroundImageStack = (image: import("gatsby-background-image").IFluidObject, color: string) => {
    var rgb = hexToRgb(color);
    return [
        image,
        `linear-gradient(rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.75), rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.35))`
    ].reverse();
};

export const HeroImage = styled(BackgroundImage)`
        margin-top: -64px;
    `;

export const HeroTagLine = styled(TextOverlayType)`
    width:100%;
    display:block;
`;

export const OverflowHiddenBox = styled(Box)`
    overflow: hidden;
`;

const HeroContainer: React.FC<HeroContainerProps> = (props) => {
    return (
        <HeroImage critical={true} fadeIn={false} fluid={getBackgroundImageStack((props.image.childImageSharp as any).fluid, props.overlayColor)}>
            <OverflowHiddenBox display="flex" flexWrap="wrap" alignItems="center" height={`${props.heroHeight}vh`} minHeight={450}>
                <>
                    <TextOverlayType shadowColor={props.overlayColor} variant="h1" align="center" gutterBottom={true}>{props.title}</TextOverlayType>
                    {props.children}
                </>
            </OverflowHiddenBox>
        </HeroImage>
    );
}

export default HeroContainer;