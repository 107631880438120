import { useStaticQuery, graphql } from "gatsby";
import Section, {CustomdBoxProps} from "./section";
import { Container } from "@material-ui/core";
import BlogList from "./blog-list";

interface BlogTeaserProps extends CustomdBoxProps {
    hideSubscribe?: boolean;
    showPosts?: boolean;
}

const BlogTeaser: React.FC<BlogTeaserProps> = (props) => {

    const data = useStaticQuery(graphql`
        query {
            blogPosts: allMarkdownRemark (
                sort: { order: DESC, fields: [frontmatter___publish_date] }
                filter: {
                    frontmatter: {
                    published: { eq: true }
                    }
                }
                limit: 3
                ){
                edges {
                    node {
                        excerpt(pruneLength: 400)
                        fields {
                            slug
                            tags: tag_slugs
                        }
                        frontmatter {
                            title
                            tags
                            publish_date(formatString: "MMMM D, YYYY")
                            primary_image {
                                childImageSharp {
                                    fluid(maxWidth: 350, maxHeight: 350, cropFocus: ENTROPY) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
            }
            blogJson: generalJson(block: {eq: "blog" }) {
                subscribeTagline
                subscribeCTA
            }
        }
    `);

    return (<Container>
            <Section {...props}>
                <BlogList posts={data.blogPosts.edges.map(e => e.node)} />
            </Section>
    </Container>);
}

export default BlogTeaser;