import React from "react";
import BackgroundImage from "gatsby-background-image";
import { getBackgroundImageStack } from './hero';
import Section, { ExtendedBoxProps } from "./section";

interface SectionWithBackgroundProps extends ExtendedBoxProps {
    overlay?: string,
    gradientTop?: number,
    gradientBottom?: number
    image: import("gatsby-source-filesystem").FileSystemNode,
}


const SectionWithBackground: React.FC<SectionWithBackgroundProps> = (props) => {
    const { overlay, image, gradientTop, gradientBottom, ...rest} = props;

    let background = (image.childImageSharp as any).fluid;
    if(overlay) {
        background = getBackgroundImageStack(background, overlay, gradientTop, gradientBottom);
    }
   
    return <BackgroundImage  fluid={background}>
        <Section {...rest}>
            {props.children}
        </Section>
    </BackgroundImage>;
}

export default SectionWithBackground;