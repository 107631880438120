import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import styled from "@emotion/styled";


interface IconTextBlockProps {
    icon: any;
    content: string;
    title: string;
}

const TextIcon = styled(FontAwesomeIcon)`
    margin: 0 8px;
`;

const IconTextBlock : React.FC<IconTextBlockProps> = (props) => {

    return <>
        <Box display="flex" alignItems="center">
            <TextIcon icon={props.icon} size="2x" />
            <Typography variant="h5">{props.title}</Typography>
        </Box>
        <ReactMarkdown source={props.content} />
    </>;
}

export default IconTextBlock;